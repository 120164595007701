.spacer{
	padding-bottom: rem-calc(70);
	&.large{
		padding-bottom: rem-calc(140);
	}
	&.small{
		padding-bottom: rem-calc(40);
	}
}

.popup-event{
	display: inline-block;
	height: 80px;
	background-color: #F8F8F8;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 14px;
	color: #000;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 5px;
	position:fixed;
	bottom: 0;
	right: 0;
	width: 33.333%;
	z-index: 5;
	outline: none;
	cursor: pointer;
	transform: translateX(100%);
	transition: transform 0.5s ease;
	&.active{
		transform: translateX(0);
	}
	@include breakpoint(large down) {
		width: 40%;
	}
	@include breakpoint(medium down) {
		width: 100%;
	}
}

.popup-modal{
	position: fixed;
	z-index: 8;
	width: 100%;
	height: 100vh;
	background-color: #F8F8F8;
	transform: translateY(110%);
	transition: transform 0.5s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	&.active{
		transform: translateY(0);
	}
	img{
		max-height: 85vh;
	}
}

.close-popup-modal{
	padding: 10px;
	position: absolute;
	top: 90px;
	right: 10px;
	cursor: pointer;
	outline: none;
	@include breakpoint(medium down) {
		top: 20px;
	}
	&>svg{
		fill:#34333C;
		width: 33px;
	}
}

.btn{
	display: inline-block;
	min-width: rem-calc(265);
	border: 5px solid $black;
	text-align: center;
	padding: rem-calc(18);
	font-size: rem-calc(16);
	text-transform: uppercase;
	text-decoration: none !important;
	color: $black;
	font-weight: 700;
	letter-spacing: 5px;
	margin-bottom: 40px;
	cursor: pointer;
	@include easeOutQuint(all, 0.5s);
	&:hover, &:focus, &:active{
		background-color: $black !important;
		color: $white !important;
		text-decoration: none !important;
	}
	&.light{
		border: 0;
		background-color: $light;
		color: $purple;
	}
	&.primary{
		background-color: $black;
		color: $white;
		&:hover, &:focus, &:active{
			opacity: 0.8;
		}
	}
	&.cart{
		background-color: $purple;
		border: 0;
		color: $white;
		transition: all 0.4s ease;
		&:hover, &:focus{
			padding-left: 45px;
			&>span{
				transform: rotate(0deg) translateX(-40px)  translateY(2px);
				opacity: 1;
			}
		}
		&>span{
			width: 20px;
			display: inline-block;
			margin-right: 20px;
			position: absolute;
			transform: rotate(15deg) translateY(2px);
			opacity: 0;
			transition: all 0.4s ease;
			transform-origin: center center;
			&>svg{
				width: 100%;
				fill: $white;
				float: left;
			}
		}
	}
	&.btn-update{
		margin-bottom: 0;
		min-width: auto;
		&>svg{
			width: 29px;
			@include easeOutQuint(all, 0.5s);
		}
		&:hover, &:focus{
			&>svg{
				fill: $white;
			}
		}
	}
	&.btn-apply{
		margin-bottom: 0;
		@include breakpoint(small only) {
			margin-top: 8px;
			width: 100%;
		}
	}
}

.form{
	label{
		margin-bottom: 45px;
		@include breakpoint(large) {
			margin-bottom: 54px;
		}
		a{
			color: $black;
			text-decoration: underline;
		}
		span{
			font-size: 18px;
			font-weight: 700;
			padding: 0px 28px 15px;
			display: inline-block;
			@include breakpoint(large) {
				font-size: 20px;
				padding: 18px 36px;
			}
		}
	}
	[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
		background-color: $white;
		padding: 25px 36px;
		border: 0;
		height: 70px;
		border-radius: 6px;
		box-shadow: 0px 0px 0px 0px rgba(0,0,0,1);
		@include customEase(box-shadow, 0.8s);
		@include breakpoint(large) {
			height: 80px;
		}
		&:focus {
			border: 0;
			box-shadow: 0px 0px 0px 3px rgba(0,0,0,1);
		}
	}
	[type='file'], [type='checkbox'], [type='radio'] {
		margin: 4px 20px 1rem 0;
	}
}

form{
	input{
		padding: 28px 15px !important;
		border-radius: 6px !important;
	}
}
.select2-container .select2-selection--single .select2-selection__rendered{
	padding-top: 28px;
	padding-bottom: 28px;
	line-height: 0 !important;
	top: 0;
	position: absolute;
	width: 100%;
}
.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #aaa;
	padding-top: 28px;
	padding-bottom: 28px;
	border-radius: 6px;
}
.select2-selection__arrow{
	transform: scale(1.5);
	position: absolute;
	top: 15px !important;
	right: 10px !important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[data-selected] {
	background-color: $black !important;
	color: $white !important;
	outline: none !important;
}
.select2-container--default .select2-results__option[aria-selected=true], .select2-container--default .select2-results__option[data-selected=true] {
	background-color: $light;
	outline: none !important;
}
.woocommerce form .form-row textarea {
	height: 10em !important;
	line-height: 1.5;
	display: block;
	box-shadow: none;
	border-radius:6px;
}

.select-lang {
	display: flex;
	height: 80px;
	align-items: center;
	justify-content: center;

	.wpml-ls-link {
		font-size: 15px;
		color: $grey;
		font-weight: bold;
		transition: color .4s ease;
		&:hover {
			color: #000;
		}
	}

	.wpml-ls-current-language {
		.wpml-ls-link {
			font-size: 14px;
			color: #000;
		}
	}

	.wpml-ls-flag, .wpml-ls-display {
		display: none!important;
	}

	.wpml-ls-legacy-list-horizontal.wpml-ls-statics-footer {
		margin: 0!important;
	}
}

.onsale {
	margin-left: 20px;
	font-weight: bold;
	color: $grey;
	text-decoration: line-through;

	@include breakpoint(medium down) {
		position: absolute;
		bottom: 25px;
		right: 250px;
	}

	@include breakpoint(small down) {
		position: absolute;
		bottom: 25px;
		right: 130px;
	}
}