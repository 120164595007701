@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700|Poppins:400,700&display=swap');

$sans-serif: 'Poppins', sans-serif;
$serif: 'Playfair Display', serif;

$bold: 700;

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-family: $sans-serif;
}

h1, .h1 {
	font-size: rem-calc(50);
	line-height: rem-calc(50);
	margin-bottom: rem-calc(37);
	margin-top: rem-calc(20);
	@include breakpoint(medium) {
		font-size: rem-calc(55);
		line-height: rem-calc(55);
	}
	@include breakpoint(large) {
		font-size: rem-calc(60);
		line-height: rem-calc(60);
	}
	@include breakpoint(xxlarge) {
		font-size: rem-calc(75);
		line-height: rem-calc(75);
	}
}
h2, .h2 {
	font-size: rem-calc(40);
	line-height: rem-calc(50);
	margin-bottom: rem-calc(37);
	@include breakpoint(medium) {
		line-height: rem-calc(60);
	}
	@include breakpoint(large) {
		font-size: rem-calc(56);
		line-height: rem-calc(81);
	}
}

h3, .h3 {
	font-size: rem-calc(24);
	line-height: rem-calc(30);
	margin-bottom: rem-calc(20);
	@include breakpoint(large) {
		font-size: rem-calc(40);
		line-height: rem-calc(40);
	}
}

h5, .h5 {
	font-size: rem-calc(18);
	line-height: rem-calc(18);
	margin-bottom: rem-calc(20);
	font-weight: bold;
	letter-spacing: 4px;
	text-transform: uppercase;
}

p, .p{
	font-size: rem-calc(16);
	line-height: rem-calc(30);
	@include breakpoint(large) {
		font-size: rem-calc(18);
		line-height: rem-calc(35);
	}
}

.h1.color-sand{
	color: $autumn-sand;
	.text-stroke {
            -webkit-text-stroke: 2px $autumn-sand;
            color: transparent;
        }
}

.serif{
	font-family: $serif;
}

.sans{
	font-family: $sans-serif;
}

.uppercase{
	text-transform: uppercase;
}

.capitalize{
	text-transform: capitalize;
}

.bold{
	font-weight: 700;
}

.regular{
	font-weight: 400;
}