$blue: #dae5f4;
$light: #F8F8F8;
$grey: #9B9B9B;
$line: #979797;
$black: #000;
$dark: #34333C;
$white: #fff;
$purple: #33333D;


// PALETTE AUTUNNO
$autumn-black: #322f22;
$autumn-blue: #1b1043;
$autumn-coral: #e66653;
$autumn-amarena: #824745;
$autumn-sunny: #ecc543;
$autumn-sand: #dfcab8;
$autumn-cinnamon: #a25945;
$autumn-red: #b84036;

.color-black{
	color: $black;
}

.color-white{
	color: $white;
}

.color-purple{
	color: $purple;
}

.color-dark{
	color: $dark;
}

.color-grey{
	color: $grey;
}