@import '../../node_modules/foundation-sites/scss/foundation';
@import '../../node_modules/swiper/src/swiper';
// @import '../../node_modules/swiper/src/components/pagination/pagination';
// @import '../../node_modules/swiper/src/components/navigation/navigation';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
// @include foundation-menu;
// @include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
@include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;


// Colors
@import 'settings/colors';

// Mixins
@import 'settings/mixins';

// Typography
@import 'settings/typography';

// Helpers
@import 'settings/helpers';

// Components
@import 'settings/components';
@import 'settings/cookies';


$col : 16.6666%;
$colm : 25%;

// .preload{
//     position: fixed;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     z-index: 999;
//     background-color: white;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

body,
html {
    margin: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    font-family: $sans-serif;
}

.grecaptcha-badge{
    display: none !important;
}

.background-grid {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;

    .column {
        width: $col;
        border-left: 1px solid rgba($line, .33);

        @media screen and (max-width: 1200px) {
            width: $colm;
        }

        &:nth-child(5) {
            @include breakpoint(medium down) {
                display: none;
            }
        }

        &:last-child {
            @media screen and (max-width: 1200px) {
                display: none;
            }
        }
    }
}

.menu-left {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $light;
    z-index: 3;
    padding: 23px 30px 30px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    @include breakpoint(medium down) {
        display: none;
    }

    #logo {
        width: 80%;
        max-width: 200px;

        &>svg {
            width: 100%;
            fill: $black;
        }
    }

    &__nav {
        display: flex;

        ul {
            margin: 0;

            li {
                list-style: none;

                a {
                    display: inline-block;
                    padding: 5px 0;
                    margin: 15% 0;
                    font-size: 15px;
                    color: $black;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 5px;
                    transition: all 0.3s ease;
                    position: relative;

                    &:before{
                        content: "";
                        position: absolute;
                        left: 0;
                        width: 0;
                        height: 100%;
                        background-color: $light;
                        opacity: 0.6;
                        @include easeOutQuint(width, 0.8s);
                    }

                    &:hover,
                    &:focus {
                        // opacity: .6;
                        &:before{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__social {
        padding: 0;
        margin: 0;
        display: inline-block;

        li {
            list-style: none;
            float: left;
            height: 18px;

            a {
                padding: 5px 10px;
                margin: 5px 5px;
            }

            a>svg {
                height: 18px;
            }
        }
    }
}

.menu-top {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;

    // @include breakpoint(medium down) {
    //     width: 100% !important;
    // }

    &__logo {
        @include breakpoint(large) {
            position: absolute;
            left: 30px;
            top: 23px;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        float: right;

        @include breakpoint(medium down) {
            display: flex;
            width: 100%;

            li:first-child {
                width: 100%;
                background-color: $light;
            }
        }

        li {
            list-style: none;
            float: left;

            .logo {
                width: 100%;
                max-width: 200px;
                display: inline-block;

                @include breakpoint(medium down) {
                    height: 64px;
                    padding-left: 27px;
                    padding-right: 27px;
                    display: flex;
                }

                &>svg {
                    width: 100%;
                }
            }

            #menu {
                background-color: $dark;
                height: 80px;
                font-size: 14px;
                color: $white;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover,
                &:focus {

                    span:after,
                    span:before {
                        width: 30px;
                    }
                }

                @include breakpoint(medium down) {
                    height: 64px;
                    width: auto !important;
                }

                span {
                    width: 25px;
                    height: 25px;
                    position: relative;
                    margin-left: 25px;

                    @include breakpoint(medium down) {
                        margin-left: 33px;
                        margin-right: 33px;

                    }

                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        height: 2px;
                        background-color: $white;
                        width: 30px;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transition: all 0.3s ease;
                    }

                    &:before {
                        margin-top: -4px;
                        width: 20px;
                    }

                    &:after {
                        margin-top: 4px;
                    }
                }
            }

            #configure {
                display: inline-block;
                height: 80px;
                background-color: $light;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: $black;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 5px;
            }
        }
    }
}

.modal-menu {
    background-color: rgba($purple, .98);
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    // padding-top: 32%;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.opened{
        opacity: 1;
        pointer-events: auto;
    }


    // a {
    //     transition: opacity 0.3s ease;

    //     &:hover {
    //         opacity: .5;
    //     }
    // }

    &__close {
        padding: 10px;
        display: inline-block;
        top: 20px;
        right: 35px;
        position: absolute;
        cursor: pointer;

        &>svg {
            width: 25px;
            fill: $white;
        }
    }

    &__list {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            overflow: hidden;

            a {
                transform: translateY(80px) rotate(6deg);
                padding: 15px;
                display: inline-block;
                font-size: 30px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 6px;
                color: transparent;
                -webkit-text-stroke: 1px $white;

                @include breakpoint(large) {
                    font-size: 48px;
                    line-height: 48px;
                    margin: 10px 0;
                }
            }
        }
    }

    &__social {
        margin: 0;
        padding: 0;
        padding-top: 30px;

        li {
            list-style: none;
            display: inline-block;

            a {
                display: inline-block;
                padding: 10px;
                color: $white;
            }

            a>svg {
                height: 20px;
                fill: $white;
            }
        }
    }
}

.main {
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 154px);
    overflow-x: hidden;

    @include breakpoint(medium down) {
        padding-top: 64px;
        width: 100% !important;
    }

    @include breakpoint(small only) {
        min-height: auto;
    }

}

.concept-body__title {
    top: 18% !important;
}

.header-body, .concept-body{
    @include breakpoint(large only) {
        height: 50vh !important;
    }
}

.header-body,
.concept-body {
    width: 100vw;
    height: 120vh;
    position: relative;
    overflow: hidden;

    @include breakpoint(medium down) {
        width: 100% !important;
        height: auto;
        margin-bottom: 75px;
    }

    &__title {
        position: absolute;
        top: 38%;
        z-index: 2;

        @include breakpoint(large only) {
            top: 25% !important;
        }

        @include breakpoint(medium down) {
            position: relative;
            top: 0;
        }

        .h1 {
            font-size: 135px;
            line-height: 135px;

            @media screen and (min-width: 1630px) {
                font-size: 170px;
                line-height: 180px;
            }

            @include breakpoint(small only) {
                font-size: 75px;
                line-height: 95px;
            }
        }

        p, .h5{
            max-width: 380px;
            padding: 20px;
            @include breakpoint(large) {
                padding: 0;
            }
        }

        .h1 span,
        .h2 span {
            display: inline-block;
            float: left;
            overflow: hidden;

            &>div {
                transform: translateX(-100%);
            }
        }

        .text-stroke {
            -webkit-text-stroke: 2px $black;
            color: transparent;
        }

        .toleft {
            @include breakpoint(large) {
                transform: translateX(-100%);
                text-align: right;

                &>div {
                    transform: translateX(100%);
                }
            }

        }
    }

    &__image {
        position: absolute;
        right: 30px;
        top: 15%;
        @include breakpoint(large only) {
            top: 100px !important;
        }

        @include breakpoint(medium down) {
            position: relative;
            top: 0;
            right: 0;
            width: 100% !important;
            padding: 0 22px;
        }

        &-title {
            position: absolute;
            bottom: 20px;
            right: 20px;

            @include breakpoint(medium down) {
                bottom: auto;
                top: 20px;
                right: 42px;
            }
        }

        img {
            width: 100%;
        }
    }

    &__video {
        video {
            position: absolute;
            width: 75%;
            top: 25%;

            @include breakpoint(medium down) {
                width: 100% !important;
                position: relative;

            }
        }
    }

    .plus-before:before {
        content: "+";
        @extend .sans;
        font-size: 25px;
        font-weight: 800;
        margin-right: 10px;
    }
}

.photo-body {
    // width: 100%;
    // padding: 150px 0;
    position: relative;
    // padding-bottom: 200px;
    max-width: 1400px;
    // margin: 0 auto;
    height: 535px;
    margin-left: 0;

    @include breakpoint (medium) {
        height: 744px;
    }

    @include breakpoint (large) {
        height: 900px;
    }

    .trigger-parallax{
        position: absolute;
        top: calc(-50% - 100px);
        @include breakpoint(small only) {
            top: calc(-108%);
        }
    }

    // @include breakpoint(small only) {
    //     padding-bottom: 200%;
    // }

    // @media screen and (min-width: 1880px) {
    //     padding-bottom: 25%;
    // }

    // @media screen and (min-width: 1630px) {
    //   padding-bottom: 150%;
    // }
    // @media screen and (min-width: 1350px) {
    //   padding-bottom: 180%;
    // }

    &__block {
        width: 28%;
        position: absolute;
        left: 5%;

        &:after {
            content: "";
            padding-bottom: 100%;
            display: block;
        }

        &.orange {
            // top: -150px;

            @include breakpoint(small only) {
                width: 50%;
                top: 46px;
                // top: -150px;
            }

            @include breakpoint(xlarge) {
                // top: -150px;
            }

            .photo-body__block__container {
                background-color: #FAE1CF;
                overflow: hidden;
            }
        }

        &.olive {
            width: 16%;
            // left: 36%;
            left: 42%;
            // top: -150px;
            position: absolute;

            @include breakpoint(small only) {
                width: 40%;
                top: 15%;
                // top: -250px;
                // left: 50%;
            }

            @include breakpoint(xlarge) {
                // top: 50px;
            }

            .photo-body__block__container {
                background-color: #D6D5CB;
            }

        }

        &.blue {
            width: 16%;
            // left: 60%;
            left: 71%;
            // top: -50px;
            position: absolute;

            @include breakpoint(small only) {
                width: 40%;
                left: 11%;
                top: 270px;
                // top: 150px;
                // left: 12%;
            }

            .photo-body__block__container {
                background-color: #D0DEEB;
            }

        }

        &.beige {
            width: 10%;
            // left: 81%;
            // top: -150px;
            z-index: -1;
            position: absolute;
            right: 5%;
            left: auto;

            @include breakpoint(xlarge) {
                // top: 35px;
            }

            .photo-body__block__container {
                background-color: #F9F4F0;
            }

        }

        &.beige1 {
            width: 16%;
            // top: -150px;
            left: 15%;
            bottom: 300px;

            @include breakpoint(xlarge) {
                // top: -150px;
            }

            @include breakpoint(xxlarge) {
                // top: 100px;
            }

            .photo-body__block__container {
                background-color: #F9F4F0;
            }

        }

        &.beige2 {
            width: 10%;
            // left: 35%;
            // top: -100px;
            position: absolute;
            left: 38%;
            bottom: 500px;

            @include breakpoint(xlarge) {
                // top: 800px;
            }

            .photo-body__block__container {
                background-color: #F9F4F0;
            }

        }

        &.beige3 {
            width: 20%;
            // left: 50%;
            // top: -250px;
            position: absolute;
            left: 52%;
            bottom: 500px;

            // @include breakpoint(medium) {
            //     top: -350px;
            // }

            // @include breakpoint(large) {
            //     top: 150px;
            // }

            // @include breakpoint(xlarge) {
            //     top: 300px;
            // }

            .photo-body__block__container {
                background-color: #F9F4F0;
                overflow: hidden;
            }

        }

        &.yellow{
            .photo-body__block__container{
                background-color: #ecc543;
                &:after{
                    display: none;
                }
            }
        }

        &.salmon {
            width: 16%;
            // left: 75%;
            // top: 250px;
            position: absolute;
            left: 75%;
            bottom: 290px;

            @include breakpoint(small only) {
                // width: 40%;
                // top: 350px;
                // left: 50%;
                width: 28%;
                left: 64%;
            }

            @include breakpoint(xlarge) {
                // top: 600px;
            }

            .photo-body__block__container {
                background-color: #FBDCD4;
            }

        }

        &__container {
            position: absolute;
            width: 100%;
            height: 100%;
            &:after{
                content: "";
                padding-bottom: 100%;
                display: block;
                background-color: #fff;
                position: relative;
                z-index: 1;
                opacity: .2;
            }

            img {
                position: absolute;
                left: 12%;
                z-index: 2;
                width: 100%;
            }
            video{
                transform: translate(-50%, -50%); height: 100%; left: 50%; top: 50%; position: absolute; z-index: 3;
            }
        }
    }
}

.btn-configure {
    padding: 25px 30px;
    width: 100%;
    border: 1px solid $purple;
    color: $purple;
    background-color: $white;

    &:hover,
    &:focus {
        background-color: $purple;
        color: $white;
    }
}

.footer {
    padding: 40px 40px 25px;
    background-color: $purple;
    position: relative;
    z-index: 1;

    @include breakpoint(medium down) {
        left: 0 !important;
        width: 100% !important;
        text-align: center;
    }

    p{
        font-size: 14px;
    }

    a {
        color: $white;

        &:hover,
        &:focus {
            color: $white;
            opacity: .8;
        }
    }

    .float-right{
        @include breakpoint(small only) {
            width: 100%;
            float: none !important;
        }
    }
}

.shop {
    padding-top: 40px;
    max-width: 100%;

    @include breakpoint(medium) {
        padding-top: 80px;
    }


    &.configure {
        .product-container {
            background-color: $light !important;

            &__image {
                filter: saturate(0);
            }
        }
    }

    .grid-margin-x, .section {
        width: 95%;
    }

    .product-container {
        width: calc(100% - 20px);
        position: relative;
        background-color: $light;
        margin-bottom: 80px;

        @include breakpoint(medium) {
            margin-bottom: 40px;
        }

        .container-overflow {
            overflow: visible;
            height: auto;
            position: absolute;
            top: initial;
            bottom: -45px;
            left: 0;
            z-index: 3;
            width: 100%;

            @include breakpoint(medium) {
                overflow: hidden;
                height: 100%;
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 3;
                width: 100%;
            }


        }

        &:hover,
        &:focus {
            @include breakpoint(medium) {
                &:after {
                    opacity: .7;
                }

                .product-container__name {
                    transform: rotate(-90deg) translateY(0);
                }

                .product-container__image {
                    bottom: -5px;
                }
            }
        }

        &:after {
            content: "";
            padding-bottom: 100%;
            display: block;
            background-color: $white;
            position: relative;
            z-index: 1;
            opacity: .6;
            transition: all 0.4s ease;
        }

        &__name {
            height: auto;
            font-weight: bold;
            // transform: rotate(0deg) translateY(-25px);
            width: 100%;
            text-align: left;
            color: $black;
            transition: all 0.4s ease;

            @include breakpoint(medium) {
                height: 100%;
                font-weight: bold;
                transform: rotate(-90deg) translateY(-25px);
                width: 100%;
                text-align: right;
                color: $black;
                transition: all 0.4s ease;
            }
        }

        &__image {
            position: absolute;
            height: 100%;
            right: -15px;
            bottom: -15px;
            z-index: 2;
            transition: all 0.4s ease;
        }
    }

    .filter-products {
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        margin-top: 50px;

        @include breakpoint(medium) {
            margin-bottom: 50px;
            margin-top: 100px;
        }

        li {
            display: inline-block;
            margin-left: -8px;
            margin-right: 20px;

            &:hover,
            &:focus,
            &.active {
                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: "";
                display: block;
                height: 3px;
                margin-left: 8px;
                width: calc(100% - 19px);
                background-color: $black;
                margin-top: 10px;
                opacity: 0;
                transition: opacity 0.4s ease;
            }

            a {
                @extend .h5;
                font-size: 14px;
                color: $black;
                padding: 5px 8px;
            }
        }
    }
}

.pdp {
    padding-top: 60px;

    @include breakpoint(medium) {
        padding-top: 100px;
    }

    .pdp-slide-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        // background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        img{
            max-height: 800px;
        }
    }

    .pdp-slide-pagination {
        width: 100%;
        text-align: center;

        &>span {
            width: 10px;
            height: 10px;
            border: 1px solid $purple;
            display: inline-block;
            margin: 5px 8px;
            border-radius: 50%;

            &.swiper-pagination-bullet-active {
                background-color: $purple;
            }
        }
    }
    .info-product{
        .h1{
            letter-spacing: 6px;

            @include breakpoint (large) {
                margin-left: -20px;
            }
        }
        p{
            padding: 15px 0;
            @include breakpoint(large) {
                padding: 15px;
            }
        }
    }
}
.pdp-buttons{
    padding: 50px 0 25px;
    .btn{
        min-width: auto !important;
    }
    @include breakpoint (large) {
        position: absolute;
    }
    @include breakpoint( medium down ){
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
        padding: 0;
        .btn{
            margin: 0;
            width: 100% !important;
            right: 0 !important;
            padding: 25px;
            &.cart{
                padding-left: 45px;
                &>span{
                    transform: rotate(0deg) translateX(-40px)  translateY(2px);
                    opacity: 1;
                }
            }
        }
    }
}
.append-mobile{
    font-size: 20vw;
    line-height: 16vw;
}
.image-with-shadow{
    margin: 40px 0 80px;
    position: relative;
    top: -10px;
    left: -10px;
    &>img{
        position: relative;
        top: 15px;
        left: 15px;
        @include breakpoint(large) {
            top: 40px;
            left: 40px;
        }
    }
}

.article-body .woocommerce{
    .cart_item{
        .product-name{
            &>a{
                display: inline-block;
                padding: 5px 0;
                margin: 0 0 25px;
                font-size: 15px;
                color: $black;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 5px;
                transition: all 0.3s ease;

                &:hover,
                &:focus {
                    opacity: .6;
                }
            }
            .variation{
                *{
                    margin-right: 10px;
                    margin-bottom: 0;
                    font-size: 0.9rem;
                    line-height: 2.1875rem;
                    font-weight: initial;
                }
            }
        }
    }
    a {
        line-height: inherit;
        color: $black;
        text-decoration: underline;
        cursor: pointer;
        &:hover, &:focus {
            color: $black;
            text-decoration: none;
        }
    }
}
.woocommerce-form-login-toggle{
    display: none;
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button{
    @extend .btn;
}

.contacts-header{
    height: auto;
    .header-body__image{
        position: relative;
        right: 0;
        width: 60% !important;
        @include breakpoint(medium down) {
            width: 100% !important;
            padding: 0;
        }
    }
}

textarea[rows] {
    height: auto !important;
}

.form-contacts{
    background-color: #F0F0F1;
}

.zoom-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 5;
    // opacity: 0;
    *{
        transition: all 200ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
        transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
        background-color: $white;
    }
}

.pdp-show-more{
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: $dark;
    margin-left: 0;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: opacity 0.4s ease;
    .plus-icon{
        font-size: 40px;
        line-height: 23px;
        display: inline-block;
        color: $white;
        margin-top: 2px;
        pointer-events: none;
        opacity: 1;
        transition: opacity 0.4s ease;
    }
    &.active{
        cursor: initial;
        z-index:5;
        transition: opacity 0.4s ease;
        *{
            opacity: 0;
            transition: opacity 0.4s ease;
        }
    }
}

.woocommerce-message {
    border-top-color: #4dd599 !important;
}
.woocommerce-message::before {
    color: #4dd599 !important;
}

.shop.configure{
    overflow: hidden;
}

app-root{
    height: 100vh;
    @include breakpoint(medium down) {
        height: calc(100vh - 64px) !important;
    }
}

.modal-container{
    position: fixed;
    top: 20%;
    z-index: 6;
    pointer-events: none;
    &__body{
        padding: 20px;
        background: white;
        width: 80%;
        margin: 0 auto;
        position: relative;
        left: 60px;
        opacity: 0;
        max-height: 70vh;
        overflow: auto;
    }
    &.active{
        pointer-events: auto;
    }
    &__close{
        width: 40px;
        padding: 8px;
        margin-left: auto;
        cursor: pointer;
        &>svg{
            fill: $dark;
        }
    }
}

.configure-container{
    display: flex;
    @include breakpoint(medium down) {
        display:block;
    }
    .left-viewer{
        width: 60%;
        position: relative;
        @include breakpoint(medium down) {
            width: 100%;
        }
        &__image{
            width: 100%;
            position: absolute;
            opacity: 0;
            left: 0;
            transform: translate(-50%, 0);
            left: 50%;
            max-width: 640px;
            margin: 0 auto;
            margin-bottom: 0;
            margin-top:80px;

            @include easeOutQuint(all, 1.2s);
            &.active{
                position: relative;
                opacity: 1;
                margin-top: 0;
                margin-bottom: 80px;
            }
        }
    }
}

.right-options{
    width: 40%;
    position: relative;
    background-color: $light;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    @include breakpoint(medium down) {
        width: 100%;
    }
    .list-configurable{
        margin:0;
        width: 100%;
        margin-bottom: 30px;
        &__item{
            list-style: none;
            margin: 10px;
            padding: 15px 40px 15px 15px;
            background-color: $white;
            min-height: 95px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            transition: padding-left 0.3s ease;
            &:hover, &:focus{
                &>svg{
                    right: 25px;
                }
            }
            &:before{
                content: "";
                width: 0px;
                background-color: $dark;
                display: block;
                height: 100%;
                position: absolute;
                left: 0;
                transition: width 0.3s ease;
            }
            &.active{
                padding-left: 35px;
                &:before{
                    width: 20px;
                }
            }
            &>svg{
                width: 13px;
                position: absolute;
                right: 35px;
                transition: right 0.3s ease;
            }
            &>*{
                margin:0;
            }
        }
    }
}

.woocommerce-privacy-policy-text>p{
    font-size: 12px;
    line-height: 17px;
    @extend .color-grey;
}

span.wpcf7-list-item {
    display: inline-block;
    margin: 0 0.5rem 0 0em !important;
}

.wc-braintree-new-payment-method-container{
    .row{
        .form-group{
            width: 100% !important;
            @include breakpoint (large) {
                width: 50% !important;
            }
            #wc-braintree-card-number{
                padding: 0;
            }
        }
    }
}

.first-content {
    padding-top: 40px;
    margin-bottom: 100px;

    p {
        max-width: 550px;
    }
}

.single-unconfigurable, .single-preconfigured {
    .menu-top {
        .show-for-large {
            display: none;
        }
    }
}

.menu-top ul li #configure {
    display: none;
}

.menu-top ul li #configure.hide-shop {
    display: flex;
}

.page-template-template-shop {

    .menu-top ul li #configure {
        display: flex;
    }


    .menu-top ul li {
        .hide-shop {
            display: none!important;
        }
    }
}